import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { MenuIcon } from "../../icons";
import { NoSsr } from "../utils/NoSsr";
import { UserCircleOutlinedIcon } from "../../icons";
import { config } from "@lib/utils/config";
import { paths } from "../../utils/paths";
import { useAmplitude } from "../../hooks/utils/useAmplitude";
import { useAuth } from "../../hooks/utils/useAuth";
import { useSuperuser } from "../../hooks/admin";
import { usePublicProfile, useSong } from "../../api/queries";
import NextLink from "next/link";
import React from "react";

interface Props {
  hideSignIn: boolean;
  sidebarPermanentlyOpen: boolean;
  setDrawerOpen: (_: boolean) => void;
  songId?: string;
  playlistId?: string;
}

interface UserSectionProps {
  hideSignIn: boolean;
  slim: boolean;
  isDemo: boolean;
}

const UserSection: React.FunctionComponent<UserSectionProps> = ({ hideSignIn, slim, isDemo }) => {
  const { user, loading, signOut } = useAuth();
  const { logEvent, events, setUserId } = useAmplitude();
  const { isSuperuser } = useSuperuser();
  const hideName = slim;

  const { data: profile, error: profileError } = usePublicProfile(user?.id);

  if (profileError) {
    throw profileError;
  }

  const handleSignOut = async () => {
    signOut();
    logEvent(events.navbar.clickSignOut);
    setUserId(null);
  };

  const isLoading = loading || (user && !profile);

  if (!user && !isLoading) {
    return (
      <Flex justifyContent="flex-end" gap="2">
        <NextLink href={paths.support} passHref>
          <Button variant={"solid"} size={"sm"} onClick={() => logEvent(events.navbar.clickSupport)}>
            Support
          </Button>
        </NextLink>
        {!hideSignIn && (
          <NextLink href={isDemo ? paths.register : paths.signIn} passHref>
            <Button
              aria-label="sign-in"
              variant={"solid"}
              colorScheme={"blue"}
              size={"sm"}
              onClick={() => logEvent(isDemo ? events.navbar.clickRegister : events.navbar.clickSignIn)}
            >
              {isDemo ? "Register" : "Sign In"}
            </Button>
          </NextLink>
        )}
      </Flex>
    );
  }

  const getSuperuserItems = () => {
    if (!isSuperuser) return null;

    return (
      <>
        <NextLink passHref href={paths.admin}>
          <MenuItem>Admin</MenuItem>
        </NextLink>
        <MenuDivider />
      </>
    );
  };

  const getDevelopmentItems = () => {
    if (config.environment !== "development") return null;

    return (
      <>
        <NextLink passHref href={paths.dev}>
          <MenuItem>Dev</MenuItem>
        </NextLink>
        <MenuDivider />
      </>
    );
  };

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            isLoading={isLoading}
            as={hideName ? IconButton : Button}
            rounded={"lg"}
            variant="outline"
            rightIcon={hideName ? undefined : <UserCircleOutlinedIcon />}
            icon={hideName ? <UserCircleOutlinedIcon /> : undefined}
            onClick={isOpen ? undefined : () => logEvent(events.navbar.clickUser)}
            overflow="hidden"
            colorScheme="blue"
          >
            {hideName || <Text isTruncated>{profile?.name}</Text>}
          </MenuButton>
          <Portal>
            <MenuList zIndex={"popover"}>
              <NextLink passHref href={paths.account}>
                <MenuItem onClick={() => logEvent(events.navbar.clickAccount)}>Account</MenuItem>
              </NextLink>
              <NextLink passHref href={paths.support}>
                <MenuItem onClick={() => logEvent(events.navbar.clickSupport)}>Support</MenuItem>
              </NextLink>
              <MenuDivider />
              <Link href={paths.donate} isExternal _hover={{ textDecoration: undefined }}>
                <MenuItem
                  onClick={() => logEvent(events.navbar.clickDonate)}
                  onAuxClick={() => logEvent(events.navbar.clickDonate)}
                >
                  Donate
                  <Box as={"span"} className="emoji" role="img" aria-label="" aria-hidden="true" pl="2">
                    ❤️
                  </Box>
                </MenuItem>
              </Link>
              <MenuDivider />
              {getSuperuserItems()}
              {getDevelopmentItems()}
              <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
            </MenuList>
          </Portal>
        </>
      )}
    </Menu>
  );
};

interface TitleSectionProps {
  songId: string;
  headingSize: "xs" | "sm";
  align: "flex-start" | "center";
}

const TitleSection: React.FunctionComponent<TitleSectionProps> = ({ songId, headingSize, align }) => {
  const { data: songData, error: songError } = useSong(songId);
  const { data: authorData, error: authorError } = usePublicProfile(songData?.userId);

  if (songError) {
    throw songError;
  }

  if (authorError) {
    throw authorError;
  }

  if (!songData || !authorData) {
    return null;
  }

  return (
    <Flex alignItems={align} direction="column" shrink={1} overflow="hidden">
      <Heading size={headingSize} isTruncated maxW="100%">
        {songData?.name}
      </Heading>
      <Text fontSize="xs" isTruncated maxW="100%">
        {authorData?.name}
      </Text>
    </Flex>
  );
};

export const Navbar: React.FunctionComponent<Props> = ({
  hideSignIn,
  sidebarPermanentlyOpen,
  setDrawerOpen,
  songId,
  playlistId: _, // TODO: Use this
}) => {
  const { user } = useAuth();
  const { logEvent, events } = useAmplitude();

  const slim: boolean = useBreakpointValue({ base: true, md: false }) ?? false;

  const height = songId ? "12" : "16";
  const showSideBarButton = !!user && !sidebarPermanentlyOpen;
  const isDemo = songId === config.demoSongId;

  return (
    <NoSsr>
      <Box
        zIndex={"banner"}
        pos={sidebarPermanentlyOpen ? "fixed" : "relative"}
        w="full"
        bg={useColorModeValue("gray.100", "gray.900")}
        px={slim ? "3" : "6"}
      >
        <Flex h={height} alignItems={"center"} justifyContent="space-between">
          <Flex w={slim ? undefined : "52"} minW="0%" maxW="85%" alignItems={"center"} shrink={1}>
            <IconButton
              size={"md"}
              icon={<MenuIcon />}
              aria-label={"Open Menu"}
              display={showSideBarButton ? "inherit" : "none"}
              onClick={() => setDrawerOpen(true)}
              mr={slim || songId ? "3" : "5"}
            />
            {slim && songId ? (
              <TitleSection songId={songId} headingSize={"xs"} align={"flex-start"} />
            ) : (
              <NextLink passHref href={user ? paths.all : paths.index}>
                <Heading size="md" onClick={() => logEvent(events.navbar.clickHeading)}>
                  trackshare
                </Heading>
              </NextLink>
            )}
          </Flex>
          {!slim && songId && <TitleSection songId={songId} headingSize={"sm"} align={"center"} />}
          <Flex w={slim ? undefined : "52"} alignItems={"center"} justifyContent="flex-end" shrink={0}>
            <UserSection hideSignIn={hideSignIn} slim={slim} isDemo={isDemo} />
          </Flex>
        </Flex>
      </Box>
      {sidebarPermanentlyOpen && <Box h={height} />}
    </NoSsr>
  );
};
