import {
  Box,
  Button,
  CloseButton,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Link,
  LinkProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spacer,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { FormTextInput } from "../forms/FormTextInput";
import { PlusIcon } from "../../icons";
import { insertPlaylist } from "../../api/commands";
import { usePlaylists } from "../../api/queries";
import { paths } from "../../utils/paths";
import { useAmplitude } from "../../hooks/utils/useAmplitude";
import { useAuth } from "../../hooks/utils/useAuth";
import { useRouter } from "next/router";
import { v4 } from "uuid";
import NextLink from "next/link";
import React, { useState } from "react";

interface Props {
  sidebarPermanentlyOpen: boolean;
  drawerOpen: boolean;
  setDrawerOpen: (_: boolean) => void;
}

export const Sidebar: React.FunctionComponent<Props> = ({ sidebarPermanentlyOpen, drawerOpen, setDrawerOpen }) => {
  return (
    <>
      <Box
        sx={{ flexShrink: 0 }}
        w={sidebarPermanentlyOpen ? 60 : 0}
        display={sidebarPermanentlyOpen ? "block" : "none"}
        borderRight="1px"
        borderRightColor={useColorModeValue("gray.200", "gray.700")}
      >
        <SidebarContent />
      </Box>
      <Drawer
        autoFocus={false}
        isOpen={drawerOpen}
        placement="left"
        onClose={() => setDrawerOpen(false)}
        returnFocusOnClose={false}
        onOverlayClick={() => setDrawerOpen(false)}
        size={"xs"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader display="flex" borderBottomWidth="1px">
            <Text>trackshare</Text>
            <Spacer />
            <CloseButton onClick={() => setDrawerOpen(false)} />
          </DrawerHeader>
          <DrawerBody px={0} w="inherit">
            <SidebarContent />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const SidebarContent = () => {
  const { data: playlistsData, error } = usePlaylists();
  const { logEvent, events } = useAmplitude();

  if (error) throw error;

  return (
    <Box pos="fixed" h="full" w="inherit">
      <NavLink href={paths.all} onClick={() => logEvent(events.sidebar.clickAllSongs)}>
        All songs
      </NavLink>
      <NavLink
        href={paths.upload}
        icon={<PlusIcon fontSize="14" />}
        onClick={() => logEvent(events.sidebar.clickUploadSong)}
      >
        Upload song
      </NavLink>
      <Divider />
      {playlistsData ? (
        <>
          {playlistsData.map((playlist, index) => (
            <NavLink
              key={index}
              href={paths.playlist(playlist.id)}
              onClick={() =>
                logEvent(events.sidebar.clickPlaylist({ playlistId: playlist.id, playlistName: playlist.name }))
              }
            >
              {playlist.name}
            </NavLink>
          ))}
          <CreatePlaylistNavItem />
        </>
      ) : (
        <Skeleton m={4} h={8} />
      )}
    </Box>
  );
};

interface NavLinkProps extends NavItemProps {
  href: string;
  children: React.ReactText;
  icon?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const NavLink = ({ href, icon, onClick, children }: NavLinkProps) => {
  return (
    <NextLink href={href}>
      <NavItem onClick={onClick}>
        {children}
        <Spacer />
        {icon}
      </NavItem>
    </NextLink>
  );
};

interface CreatePlaylistData {
  name: string;
}

const CreatePlaylistNavItem = () => {
  const { user } = useAuth();
  const { mutate } = usePlaylists();
  const router = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { logEvent, events, incrementUserProperty } = useAmplitude();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const methods = useForm<CreatePlaylistData>({
    defaultValues: { name: "" },
  });

  if (!user) return null;

  const onSubmit = async ({ name }: CreatePlaylistData) => {
    setIsSubmitting(true);

    const id = v4();
    await insertPlaylist({ id, userId: user.id, name: name });
    mutate();
    logEvent(events.sidebar.createPlaylist({ playlistId: id, playlistName: name }));
    incrementUserProperty("playlists");
    router.push(paths.playlist(id));
    onClose();
  };

  const openModal = () => {
    methods.reset();
    onOpen();
    logEvent(events.sidebar.clickCreatePlaylist);
  };

  return (
    <>
      <NavItem onClick={openModal}>
        Create playlist
        <Spacer />
        <PlusIcon fontSize="14" />
      </NavItem>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent as="form" autoComplete="off" noValidate onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalHeader>Create playlist</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormProvider {...methods}>
              <FormTextInput
                id="name"
                label="Playlist name"
                options={{
                  required: "Required",
                }}
              />
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" isLoading={isSubmitting}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

interface NavItemProps extends LinkProps {
  children: React.ReactNode;
}

const NavItem = React.forwardRef<HTMLAnchorElement, NavItemProps>(({ children, ...rest }: NavItemProps, ref) => (
  <Link as="span" ref={ref} style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }} {...rest}>
    <Flex
      w="100%"
      align="center"
      alignItems="center"
      py={4}
      px={8}
      borderRadius="lg"
      role="group"
      cursor="pointer"
      _hover={{
        color: "blue.400",
      }}
    >
      {children}
    </Flex>
  </Link>
));

NavItem.displayName = "NavItem";
