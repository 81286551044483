import { FieldError, FieldPath, FieldValues, RegisterOptions, useFormContext } from "react-hook-form";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Textarea,
} from "@chakra-ui/react";
import React from "react";

type FormTextInputProps<TFieldValues extends FieldValues, TFieldName> = {
  id: TFieldName;
  label: string;
  disabled?: boolean;
  options: RegisterOptions<TFieldValues>;
  type?: "email" | "text" | "password";
  multiline?: boolean;
  leftAdornment?: React.ReactNode;
  rightAdornment?: React.ReactNode;
};

export function FormTextInput<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  id,
  label,
  options,
  disabled = false,
  type = "text",
  multiline = false,
  leftAdornment = null,
  rightAdornment = null,
}: FormTextInputProps<TFieldValues, TFieldName>) {
  const {
    register,
    formState: { errors },
  } = useFormContext<TFieldValues>();

  const error = errors[id] as FieldError;

  if (type !== "text" && multiline) {
    throw new Error("Multiline text inputs can only be of type text");
  }

  return (
    <>
      <FormControl isInvalid={!!error} isRequired={!!options.required} isDisabled={disabled}>
        <FormLabel htmlFor={id}>{label}</FormLabel>
        <InputGroup>
          {leftAdornment && <InputLeftElement pointerEvents="none">{leftAdornment}</InputLeftElement>}
          {multiline ? (
            <Textarea id={id} {...register(id, options)} disabled={disabled} />
          ) : (
            <Input id={id} {...register(id, options)} type={type} disabled={disabled} />
          )}
          {rightAdornment && <InputRightElement>{rightAdornment}</InputRightElement>}
        </InputGroup>
        <FormErrorMessage>{error && error.message}</FormErrorMessage>
      </FormControl>
    </>
  );
}
