import {
  Box,
  Flex,
  Link,
  Text,
} from "@chakra-ui/react";
import { NoSsr } from "../utils/NoSsr";
import React from "react";

interface Props {
  sidebarPermanentlyOpen: boolean;
  display: boolean
}

export const SystemBanner: React.FunctionComponent<Props> = ({
  sidebarPermanentlyOpen,
  display
}) => {
  const height = "12";

  if (!display) {
    return null;
  }

  return (
    <NoSsr>
      <Box
        zIndex={"banner"}
        pos={sidebarPermanentlyOpen ? "fixed" : "relative"}
        w="full"
        bg={"orange.200"}
        px={"6"}
      >
        <Flex minH={height} alignItems={"center"} justifyContent="space-between">
          <Text textAlign="center" width="100%">trackshare is currently experiencing intermittent issues due to an upstream service provider. For updates, join <Link textDecoration={"underline"} href="https://www.facebook.com/groups/706475751586794" isExternal>our user group</Link>.</Text>
        </Flex>
      </Box>
      {sidebarPermanentlyOpen && <Box minH={height} />}
    </NoSsr >
  );
};
