import { Container, Flex, useBreakpointValue } from "@chakra-ui/react";
import { Navbar } from "./Navbar";
import { Sidebar } from "./Sidebar";
import { SystemBanner } from "./SystemBanner";
import { useAuth } from "../../hooks/utils/useAuth";
import { useTitle } from "../../hooks/utils/useTitle";
import React, { useEffect, useState } from "react";

interface Props {
  children: React.ReactNode;
  requireAuthenticated: boolean;
  songId?: string;
  playlistId?: string;
  title?: string;
  enableSidebar: boolean;
  hideSignIn?: boolean;
  containerWidth: "md" | "xl" | "full";
}

export const Layout: React.FunctionComponent<Props> = ({
  children,
  requireAuthenticated,
  title,
  enableSidebar,
  containerWidth,
  songId,
  playlistId,
  hideSignIn = false,
}) => {
  const { setTitle } = useTitle();
  const { user, loading } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [initiallyAuthed, setInitiallyAuthed] = useState<boolean>(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState<boolean>(false);
  const sidebarPermanentlyOpen = useBreakpointValue({ base: false, lg: true }) || false;

  useEffect(() => {
    if (title) {
      setTitle(title);
    }
    return () => {
      setTitle(undefined);
    };
  }, [title, setTitle]);

  useEffect(() => {
    if (user) {
      setInitiallyAuthed(true);
    }
  }, [user]);

  // By checking initiallyAuthed here, we can keep the side nav visible whilst redirecting
  // to index after the user signs out - otherwise it'll pop out during the transition
  const showSidebar = enableSidebar && (user || initiallyAuthed);

  const isFullWidth = containerWidth === "full";
  const maxW = isFullWidth ? "full" : `container.${containerWidth}`;

  const calculatedSidebarPermanentlyOpen = !isFullWidth && sidebarPermanentlyOpen;

  const getContents = () => {
    // This prevents redirecting to sign in when the user signs out
    if (initialLoadComplete) return children;

    if (loading) {
      // TODO: return loading state
      return null;
    } else if (requireAuthenticated && !user) {
      return null;
    } else {
      setInitialLoadComplete(true);
      return children;
    }
  };

  return (
    <Flex w="full" h="100vh" direction="column">
      <Flex grow={0}>
        <SystemBanner sidebarPermanentlyOpen={calculatedSidebarPermanentlyOpen} display={false} />
      </Flex>
      <Flex grow={0}>
        <Navbar
          setDrawerOpen={setDrawerOpen}
          sidebarPermanentlyOpen={calculatedSidebarPermanentlyOpen}
          hideSignIn={hideSignIn}
          songId={loading ? undefined : songId}
          playlistId={loading ? undefined : playlistId}
        />
      </Flex>
      <Flex grow={1}>
        {showSidebar && (
          <Sidebar
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            sidebarPermanentlyOpen={calculatedSidebarPermanentlyOpen}
          />
        )}
        <Flex grow={1} maxW={isFullWidth ? "full" : undefined}>
          <Container py={isFullWidth ? 0 : 30} maxW={maxW} px={isFullWidth ? 0 : 5} h="100%">
            {getContents()}
          </Container>
        </Flex>
      </Flex>
    </Flex>
  );
};
